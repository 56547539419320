import React from "react";
import Link from "next/link";
import PastTimer from "../DateandTime/PastTimer";
import { roundToDecimalPlaces } from "../../utils/basicFunctions";

const OfferTab = ({ placeBidsData, userMap, startingPrice, ethToUsdRate }) => {
  return (
    <>
      {/* <!-- Offers --> */}
      <div
        className="tab-pane fade show active"
        id="offers"
        role="tabpanel"
        aria-labelledby="offers-tab"
      >
        <div
          role="table"
          className="scrollbar-custom bg-[#1e1e1e] dark:border-jacarta-600 border-jacarta-100 grid max-h-72 w-full grid-cols-5 overflow-y-auto rounded-lg rounded-tl-none border text-sm dark:text-white"
        >
          <div className="contents" role="row">
            <div
              className="bg-jacarta-600 sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Price
              </span>
            </div>
            <div
              className="bg-jacarta-600 sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                USD Price
              </span>
            </div>
            <div
              className="bg-jacarta-600 sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Floor Difference
              </span>
            </div>
            <div
              className="bg-jacarta-600 sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Offer Placed at
              </span>
            </div>
            <div
              className="bg-jacarta-600 sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                From
              </span>
            </div>
          </div>
          {placeBidsData.map((item, index) => {
            const { senderWallet, price, happenedAt } = item;

            const etherPrice = Number(price);
            return (
              <div className="contents" role="row" key={index}>
                <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center whitespace-nowrap border-t py-4 px-4"
                  role="cell"
                >
                  <span className="-ml-1" data-tippy-content="ETH">
                    <svg className="icon mr-1 h-4 w-4">
                      <use xlinkHref="/icons.svg#icon-ETH"></use>
                    </svg>
                  </span>
                  <span className="text-green text-sm font-medium tracking-tight">
                    {etherPrice} ETH
                  </span>
                </div>
                <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                  {(etherPrice * ethToUsdRate).toFixed(2)} USD
                </div>
                <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                  {roundToDecimalPlaces(
                    (etherPrice - startingPrice / 10 ** 18) /
                      (startingPrice / 10 ** 20),
                    0
                  )}
                  {"% "}
                  above
                </div>
                <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                  <PastTimer duration={new Date() - new Date(happenedAt)} />
                </div>
                <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                  <Link
                    href={`/user/${userMap[senderWallet]?.username}`}
                    className="text-accent"
                  >
                    {userMap[senderWallet]?.name}
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OfferTab;
