import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import OfferTab from "./OfferTab";
import Properties from "./Properties";
import Activity_tab from "./Activity_tab";
import Price_history from "./Price_history";
import { fetchWithRetry } from "../../utils/basicFunctions";

const tabsHeadText = [
  {
    id: 1,
    text: "Offers",
    icon: "offers",
  },
  {
    id: 2,
    text: "properties",
    icon: "properties",
  },
  {
    id: 3,
    text: "details",
    icon: "details",
  },
  {
    id: 4,
    text: "activities",
    icon: "activity",
  },
  {
    id: 5,
    text: "price history",
    icon: "price",
  },
];

const ItemsTabs = ({
  pid,
  ownerAddress,
  getCollectionData,
  userMap,
  startingPrice,
  ethToUsdRate,
  transactionsData,
}) => {
  const [tabsActive, setTabsActive] = useState(1);

  const [nftActivities, setNftActivities] = useState([]);
  const [avgPriceArr, setAvgPriceArr] = useState([]);
  const [salesArr, setSalesArr] = useState([]);
  const [collectionData, setCollectionsData] = useState({});
  const [placeBidsData, setPlaceBidsData] = useState([]);

  useEffect(() => {
    const getCollection = async () => {
      const response = await fetchWithRetry(
        `/api/collections/get-collections?userWallet=${getCollectionData.creatorWallet}&collectionName=${getCollectionData.collectionName}`
      );
      const data = await response.json();
      if (data.collections) {
        setCollectionsData(data.collections);
      }
    };
    if (getCollectionData?.collectionData && getCollectionData?.collectionName)
      getCollection();
  }, [getCollectionData]);

  useEffect(() => {
    if (pid === 0 || pid) {
      const data = transactionsData.filter((item) => item.tokenId == pid);
      if (data) {
        const activitiesData = data
          .slice(0)
          .sort((a, b) => new Date(b.happenedAt) - new Date(a.happenedAt));
        setNftActivities(activitiesData);
      }
    }
  }, [pid, transactionsData]);

  useEffect(() => {
    const bidsPlaceArr = nftActivities.filter(
      (item) => item.eventName === "bids" && item.action === "place"
    );
    const avg_arr = nftActivities.filter(
      (item) =>
        (item.eventName === "listings" || item.eventName === "bids") &&
        item.action === "create"
    );
    const sales_arr = nftActivities.filter(
      (item) => item.eventName === "transfer"
    );

    avg_arr.sort((a, b) => new Date(a.happenedAt) - new Date(b.happenedAt));
    sales_arr.sort((a, b) => new Date(a.happenedAt) - new Date(b.happenedAt));

    setPlaceBidsData(bidsPlaceArr);
    setAvgPriceArr(avg_arr);
    setSalesArr(sales_arr);
  }, [nftActivities]);

  return (
    <>
      <div className="scrollbar-custom mt-14 overflow-x-auto rounded-lg">
        {/* <!-- Tabs Nav --> */}
        <Tabs className="min-w-fit tabs">
          <TabList className="nav nav-tabs flex items-center">
            {/* <!-- Offers --> */}
            {tabsHeadText.map(({ id, text, icon }) => {
              return (
                <Tab className="nav-item bg-transparent" key={id}>
                  <button
                    className={
                      tabsActive === id
                        ? "nav-link text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 hover:text-white active"
                        : "nav-link text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 hover:text-white"
                    }
                    onClick={() => setTabsActive(id)}
                  >
                    <svg className="icon mr-1 h-5 w-5 fill-white">
                      <use xlinkHref={`/icons.svg#icon-${icon}`}></use>
                    </svg>
                    <span className="font-display text-base text-white font-medium">
                      {text}
                    </span>
                  </button>
                </Tab>
              );
            })}
          </TabList>

          <TabPanel className="tab-content">
            <OfferTab
              placeBidsData={placeBidsData}
              userMap={userMap}
              startingPrice={startingPrice}
              ethToUsdRate={ethToUsdRate}
            />
          </TabPanel>
          <TabPanel>
            <Properties />
          </TabPanel>
          <TabPanel>
            {/* <!-- Details --> */}
            <div
              className="tab-pane fade"
              id="details"
              role="tabpanel"
              aria-labelledby="details-tab"
            >
              <div className="bg-[#1e1e1e] border-jacarta-600 rounded-t-2lg rounded-b-2lg rounded-tl-none border p-6 md:p-10">
                <div className="mb-2 flex items-center">
                  <span className="text-jacarta-300 mr-2 min-w-[9rem]">
                    Contract Address:
                  </span>
                  <a
                    href={`/user/${userMap[ownerAddress]?.username}`}
                    className="text-accent"
                  >
                    {ownerAddress}
                  </a>
                </div>
                <div className="mb-2 flex items-center">
                  <span className="text-jacarta-300 mr-2 min-w-[9rem]">
                    Token ID:
                  </span>
                  <span
                    className="js-copy-clipboard select-none text-white"
                    data-tippy-content="Copy"
                  >
                    {pid}
                  </span>
                </div>
                <div className="mb-2 flex items-center">
                  <span className="text-jacarta-300 mr-2 min-w-[9rem]">
                    Token Standard:
                  </span>
                  <span className="text-white">
                    {collectionData?.token_symbol}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-jacarta-300 mr-2 min-w-[9rem]">
                    Blockchain:
                  </span>
                  <span className="text-white">
                    {collectionData?.blockchain
                      ? collectionData.blockchain
                      : "Ethereum"}
                  </span>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <Activity_tab nftActivities={nftActivities} userMap={userMap} />
          </TabPanel>
          <TabPanel>
            <Price_history
              avgPriceArr={avgPriceArr}
              salesArr={salesArr}
              classes="border-jacarta-600 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-[#1e1e1e] p-6"
            />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default ItemsTabs;
