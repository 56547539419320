import HeadLine from "../headLine";
import { newseLatterData } from "../../data/newseLatterData";
import Image from "next/image";

const NewseLatter = ({ bgWhite = true }) => {
  return (
    <section className="relative py-24">
      {bgWhite && (
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full object-cover"
            width={1559}
            height={761}
          />
        </picture>
      )}

      <div className="container">
        <p className="text-white mx-auto mt-20 max-w-2xl text-center text-lg dark:text-white">
          Join our mailing list to stay in the loop with our newest feature
          releases, NFT drops, and tips and tricks for navigating Xhibiter
        </p>

        <div className="mx-auto mt-7 max-w-md text-center">
          <form className="relative" onSubmit={(e) => e.preventDefault()}>
            <input
              type="email"
              placeholder="Email address"
              className="bg-[#4d4d4d] dark:bg-white dark:border-white ring-transparent placeholder:text-white border-transparent w-full rounded-full py-3 px-4 dark:text-white dark:placeholder-white"
            />
            <button className="absolute bg-accent shadow-accent-volume hover:bg-accent-dark inline-block top-2 right-2 rounded-full py-2 px-6 text-center text-sm font-semibold text-white transition-all btn-grad ">
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default NewseLatter;
