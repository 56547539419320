import React, { useState, useEffect } from "react";
import {
  Chart,
  LineController,
  LineElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

const monthsMap = {
  0: "january",
  1: "feb",
  2: "mar",
  3: "apr",
  4: "may",
  5: "jun",
  6: "jul",
  7: "aug",
  8: "sep",
  9: "oct",
  10: "nov",
  11: "dec",
  12: "january",
  13: "feb",
  14: "mar",
  15: "apr",
  16: "may",
  17: "jun",
  18: "jul",
  19: "aug",
  20: "sep",
  21: "oct",
  20: "nov",
  21: "dec",
};
Chart.register(
  CategoryScale,
  LineController,
  LineElement,
  LinearScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);
const Price_history = ({ classes, avgPriceArr, salesArr }) => {
  const [avgPrice, setAvgPrice] = useState();
  const [sales, setSales] = useState();
  const [chartData, setChartData] = useState({
    labels: [],
    avgPrice: [],
    sales: [],
  });
  const [filterValue, setFilterValue] = useState("all");

  const roundToDecimalPlaces = (num, decimalPlaces) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
  };

  const filterLastNDaysAvgPrice = (avgPriceArr, n) => {
    const currentDate = new Date();
    return avgPriceArr.filter((price) => {
      const priceDate = new Date(price.happenedAt);
      const diffTime = Math.abs(currentDate - priceDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays <= n;
    });
  };
  const filterLastNDaysSales = (salesArr, n) => {
    const currentDate = new Date();
    return salesArr.filter((sale) => {
      const saleDate = new Date(sale.happenedAt);
      const diffTime = Math.abs(currentDate - saleDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays <= n;
    });
  };

  useEffect(() => {
    switch (filterValue) {
      case "7 days":
        const last7Arr = filterLastNDaysAvgPrice(avgPriceArr, 7);
        const last7SalesArr = filterLastNDaysSales(salesArr, 7);
        setSales(
          last7SalesArr.reduce((acc, cur) => acc + Number(cur.price), 0)
        );
        setAvgPrice(
          last7Arr.reduce(
            (acc, cur) => acc + Number(cur.price) / cur.quantity,
            0
          ) / last7Arr.length
        );
        break;
      case "14":
        const last14Arr = filterLastNDaysAvgPrice(avgPriceArr, 14);
        const last14SalesArr = filterLastNDaysSales(salesArr, 14);
        setSales(
          last14SalesArr.reduce((acc, cur) => acc + Number(cur.price), 0)
        );
        setAvgPrice(
          last14Arr.reduce(
            (acc, cur) => acc + Number(cur.price) / cur.quantity,
            0
          ) / last14Arr.length
        );
        break;
      case "30":
        const last30Arr = filterLastNDaysAvgPrice(avgPriceArr, 30);
        const last30SalesArr = filterLastNDaysSales(salesArr, 30);
        setSales(
          last30SalesArr.reduce((acc, cur) => acc + Number(cur.price), 0)
        );
        setAvgPrice(
          last30Arr.reduce(
            (acc, cur) => acc + Number(cur.price) / cur.quantity,
            0
          ) / last30Arr.length
        );
        break;
      case "60":
        const last60Arr = filterLastNDaysAvgPrice(avgPriceArr, 60);
        const last60SalesArr = filterLastNDaysSales(salesArr, 60);
        setSales(
          last60SalesArr.reduce((acc, cur) => acc + Number(cur.price), 0)
        );
        setAvgPrice(
          last60Arr.reduce(
            (acc, cur) => acc + Number(cur.price) / cur.quantity,
            0
          ) / last60Arr.length
        );
        break;
      case "90":
        const last90Arr = filterLastNDaysAvgPrice(avgPriceArr, 90);
        const last90SalesArr = filterLastNDaysSales(salesArr, 90);
        setSales(
          last90SalesArr.reduce((acc, cur) => acc + Number(cur.price), 0)
        );
        setAvgPrice(
          last90Arr.reduce(
            (acc, cur) => acc + Number(cur.price) / cur.quantity,
            0
          ) / last90Arr.length
        );
        break;
      case "last year":
        const lastYearArr = filterLastNDaysAvgPrice(avgPriceArr, 365);
        const lastYearSales = filterLastNDaysSales(salesArr, 90);
        setSales(
          lastYearSales.reduce((acc, cur) => acc + Number(cur.price), 0)
        );
        setAvgPrice(
          lastYearArr.reduce(
            (acc, cur) => acc + Number(cur.price) / cur.quantity,
            0
          ) / lastYearArr.length
        );
        break;
      default:
        setSales(salesArr.reduce((acc, cur) => acc + Number(cur.price), 0));
        setAvgPrice(
          avgPriceArr.reduce(
            (acc, cur) => acc + Number(cur.price) / cur.quantity,
            0
          ) / avgPriceArr.length
        );
        break;
    }
  }, [filterValue, avgPriceArr, salesArr]);

  useEffect(() => {
    const currDate = new Date();
    const minDate = Math.min(
      avgPriceArr[0] ? new Date(avgPriceArr[0].happenedAt) : Infinity,
      salesArr[0] ? new Date(salesArr[0].happenedAt) : Infinity
    );
    const maxDate = Math.max(
      avgPriceArr[avgPriceArr.length - 1]
        ? new Date(avgPriceArr[avgPriceArr.length - 1].happenedAt)
        : 0,
      salesArr[salesArr.length - 1]
        ? new Date(salesArr[salesArr.length - 1].happenedAt)
        : 0
    );
    const diffTime = Math.abs(new Date() - minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const startYear = new Date(minDate).getFullYear();
    const endYear = new Date(maxDate).getFullYear();

    const startMonth = new Date(minDate).getMonth();
    let endMonth = new Date().getMonth();
    if (endMonth < startMonth) endMonth += 12;

    const labels = [];
    const tempAvg = [];
    const tempSales = [];
    if (diffDays > 365) {
      for (let i = startYear; i <= endYear; i++) {
        labels.push(i);
      }

      for (const label of labels) {
        tempAvg.push(
          roundToDecimalPlaces(
            avgPriceArr
              .filter(
                (price) => new Date(price.happenedAt).getFullYear() === label
              )
              .reduce((acc, cur) => acc + Number(cur.price) / cur.quantity, 0) /
              avgPriceArr.length,
            4
          )
        );
        tempSales.push(
          salesArr
            .filter((sale) => new Date(sale.happenedAt).getFullYear() === label)
            .reduce((acc, cur) => acc + cur.quantity, 0)
        );
      }
    } else if (diffDays > 31) {
      for (let i = startMonth; i <= endMonth; i++) {
        labels.push(monthsMap[i]);
      }
      for (const label of labels) {
        tempAvg.push(
          roundToDecimalPlaces(
            avgPriceArr
              .filter(
                (price) =>
                  monthsMap[new Date(price.happenedAt).getMonth()] === label
              )
              .reduce((acc, cur) => acc + Number(cur.price) / cur.quantity, 0) /
              avgPriceArr.length,
            4
          )
        );
        tempSales.push(
          salesArr
            .filter(
              (sale) =>
                monthsMap[new Date(sale.happenedAt).getMonth()] === label
            )
            .reduce((acc, cur) => acc + cur.quantity, 0)
        );
      }
    } else {
      let timeStamplabels = [];
      for (let i = minDate; i <= currDate; i += 86400000) {
        timeStamplabels.push({
          date: new Date(i).getDate(),
          month: new Date(i).getMonth(),
        });
        labels.push(
          `${new Date(i).getDate()} ${monthsMap[new Date(i).getMonth()]}`
        );
      }
      // console.log("timeStamplabels", timeStamplabels);
      for (const label of timeStamplabels) {
        tempAvg.push(
          roundToDecimalPlaces(
            avgPriceArr
              .filter(
                (price) =>
                  new Date(price.happenedAt).getDate() === label.date &&
                  new Date(price.happenedAt).getMonth() === label.month
              )
              .reduce((acc, cur) => acc + Number(cur.price) / cur.quantity, 0) /
              avgPriceArr.length,
            4
          )
        );
        tempSales.push(
          salesArr
            .filter(
              (sale) =>
                new Date(sale.happenedAt).getDate() === label.date &&
                new Date(sale.happenedAt).getMonth() === label.month
            )
            .reduce((acc, cur) => acc + cur.quantity, 0)
        );
      }
    }

    setChartData({
      labels,
      avgPrice: tempAvg,
      sales: tempSales,
    });
  }, [avgPriceArr, salesArr]);

  return (
    <div className="relative mb-24 w-full">
      {/* <!-- Price History --> */}
      <div className="tab-pane fade">
        <div className={classes}>
          {/* <!-- Period / Stats --> */}
          <div className="mb-10 flex flex-wrap items-center">
            <select
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              className="border-jacarta-600 mr-8 min-w-[12rem] rounded-lg py-3.5 text-sm text-white bg-[#111111]"
            >
              <option value="7 days">Last 7 Days</option>
              <option value="14 days">Last 14 Days</option>
              <option value="30 days">Last 30 Days</option>
              <option value="60 days">Last 60 Days</option>
              <option value="90 days">Last 90 Days</option>
              <option value="last year">Last Year</option>
              <option value="all">All Time</option>
            </select>

            <div className="py-2">
              <span className="mr-4 inline-block align-middle">
                <span className="block text-sm font-bold text-white">
                  {filterValue} Avg. Price:
                </span>
                <span className="text-green block text-sm font-bold">
                  Ξ{roundToDecimalPlaces(avgPrice, 4)}
                </span>
              </span>

              <span className="inline-block align-middle">
                <span className="block text-sm font-bold text-white">
                  {filterValue} Volume:
                </span>
                <span className="text-green block text-sm font-bold">
                  Ξ{roundToDecimalPlaces(sales, 4)}
                </span>
              </span>
            </div>
          </div>

          {/* <!-- Chart --> */}
          <div className="chart-container relative h-80 w-full">
            <Bar
              data={{
                labels: chartData.labels,
                datasets: [
                  {
                    type: "line",
                    label: "Avg. price",
                    backgroundColor: "#10B981",
                    borderColor: "#10B981",
                    data: chartData.avgPrice,
                  },
                  {
                    type: "bar",
                    label: "Sales",
                    backgroundColor: "#E7E8EC",
                    data: chartData.sales,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                interaction: {
                  intersect: false,
                  mode: "index",
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    ticks: {
                      stepSize: 50,
                    },
                  },
                },
                plugins: {
                  legend: { display: false },
                  decimation: {
                    enabled: true,
                  },
                  tooltip: {
                    usePointStyle: true,
                    position: "nearest",
                    backgroundColor: "#111111",
                    titleAlign: "center",
                    bodyAlign: "center",
                    footerAlign: "center",
                    padding: 12,
                    displayColors: false,
                    yAlign: "bottom",
                  },
                },
                animation: false,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Price_history;
